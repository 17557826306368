var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.items,"options":_vm.table_options,"hide-default-footer":"","no-data-text":"Товары, удовлетворяющие выбранным параметрам, отсутствуют"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number)+" ")]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.$style.nomenclatureText, 'overflow-text']},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])]}},{key:"item.return_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.return_type.name)+" ")]}},{key:"item.return_reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.return_reason.name)+" ")]}},{key:"item.return_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.return_date))+" ")]}},{key:"item.return_status",fn:function(ref){
var item = ref.item;
return [(item.return_status)?_c('ChipStatus',{attrs:{"status":_vm.get_dispatch_return_status(item.return_status)}},[_vm._v(" "+_vm._s(item.return_status.name)+" ")]):_vm._e()]}},{key:"item.returned_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.returned_at))+" ")]}},{key:"item.register_realization_corrective_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.register_realization_corrective_id)?_c('v-btn',{staticClass:"ml-n4",attrs:{"to":item.register_realization_corrective_id.toString(),"append":"","text":""}},[_vm._v(" "+_vm._s(item.register_realization_corrective_number)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.register_realization_corrective_number)+" ")])],1)]}},{key:"item.register_realization_corrective_status",fn:function(ref){
var item = ref.item;
return [(item.register_realization_corrective_status)?_c('ChipStatus',{class:_vm.$style.status,attrs:{"status":_vm.get_dispatch_status(item.register_realization_corrective_status)}},[_vm._v(" "+_vm._s(item.register_realization_corrective_status.name)+" ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":_vm.set_page,"page_size_changed":_vm.set_per_page}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }